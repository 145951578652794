<template>
    <div class="accessForm" :key="key" v-for="(item, key) of Object.keys(allPermissions)">
        <h4>{{ getPermissionName(item) }}</h4>
        <div class="contentHolder">

            <div class="row" :key="index" v-for="(opt, index) of allPermissions[item]">
                <div class="title" style="text-transform: capitalize;">{{ getActionName(item, opt) }}</div>
                <div class="value">
                    <CheckSwitch :value="getPermissionActionValue(item, opt)" @change="updatePermissions(item, opt, $event)"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['modelValue'],
        data() {
            return {
                allPermissions: {},
                permissions: this.modelValue || { loads: ['view', 'editAll', 'target'] },
                info: {
                    loads: {
                        name: 'Loads',
                        permissions: {
                            view: { name: 'View', desc: 'Ability to view loads' },
                            add: { name: 'Add', desc: 'Ability to add new loads' },
                            edit: { name: 'Edit', desc: 'Ability to edit loads (only the ones user added)' },
                            editAll: { name: 'Edit all', desc: `Ability to edit other user's loads (for after hours)` },
                            target: { name: 'Target', desc: `Ability to manage weekly target` },
                        }
                    },
                    users: {
                        name: 'Users',
                        permissions: {
                            view: { name: 'View', desc: 'Ability to view users' },
                            add: { name: 'Add', desc: 'Ability to add new users' },
                            edit: { name: 'Edit', desc: 'Ability to edit and delete users' },
                        }
                    }
                }
            }
        },
        methods: {
            getPermissionName(name) {
                if(this.info[name] && this.info[name].name) return this.info[name].name
                return name;
            },
            getActionName(item, opt) {
                if(this.info[item] && this.info[item].permissions 
                && this.info[item].permissions[opt] && this.info[item].permissions[opt].name) return this.info[item].permissions[opt].name;
                return opt;
            },
            updatePermissions(item, action, val) {
                if(!this.permissions[item]) this.permissions[item] = [];
                if(val) this.permissions[item].push(action);
                    else this.permissions[item].splice(this.permissions[item].indexOf(action), 1);
            },
            getPermissionActionValue(item, action) {
                if(!this.permissions[item]) return false;
                if(this.permissions[item].includes(action)) return true;
                return false;
            }
        },
        mounted() {

            this.$axios.get('/permissions')
            .then(data => {
                this.allPermissions = data.data;
            });

            this.$emit('update:modelValue', this.permissions);

        },
        watch: {
            permissions: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.permissions);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.accessForm {
    display: grid;
    row-gap: 10px;
}
</style>