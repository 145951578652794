<template>
    <div class="invitationGrid">

        <Input v-model="item.email" placeholder="Email address" name="Email address" :error="errors.email"/>


        <div class="input">
            <div class="_header">
                <label>Position</label>
                <span class="_error">{{ errors.position }}</span>
            </div>
            <div class="inputHolder">
                <select v-model="item.position">
                    <option :value="undefined">-- Select position --</option>
                    <option :key="key" v-for="(pos, key) of positions">{{ pos }}</option>
                </select>
            </div>
        </div>

        <Expandable title="Manage access">
            <PermissionSelector v-model="item.permissions"/>
        </Expandable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionSelector from './PermissionSelector.vue'

    export default {
        components: {
            PermissionSelector
        },
        props: ['modelValue', 'errors'],
        data() {
            return {
                item: this.modelValue || {}
            }
        },
        computed: {
            ...mapGetters(['positions'])
        },
        watch: {
            modelValue() {
                this.item = this.modelValue || {};
            },
            item: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.item);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.invitationGrid {
    display: grid;
    row-gap: 20px;
}
</style>