export default {
    data() {
        return {
            
        }
    },
    methods: {
        fileSelected(ref, inputName, uploadPath, callback) {

            let el = this.$refs[ref];
            if(!el) {
                console.log("Ref. El doesn't exist");
                return;
            }

            let file = el.files[0];

            if(!file) {
                console.log("FileUploader: no file selected");
                return;
            }

            var formData = new FormData();
            formData.append(inputName, file);
            this.ajax('uploadLoadDocument', {
                method: 'POST',
                url: `${uploadPath}`,
                data: formData,
            }, (err, body) => {
                callback(err, body);
                el.value = '';
            });

        }
    }
}