<template>
    <div class="addUserForm">  
        <div class="tabs">
            <button :key="key" class="default small round" :class="{ 'reverse' : key != selectedTab }" @click="selectedTab = key" v-for="(tab, key) of tabs">{{ tab.name }}</button>
        </div>

        <div class="componentContent">
            <component v-bind:is="tabs[selectedTab].component" :details="item" @update="updateDetails"></component>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MainUserDetails from './MainUserDetails.vue'
import SalaryAndBonuses from './SalaryAndBonuses.vue'
import Access from './Access.vue'
import CustomPercentage from './CustomPercentage.vue'
import CredentialsManager from './CredentialsManager.vue'
import ProfileImage from './ProfileImage.vue'

    export default {
        props: ['modelValue'],
        components: {
            MainUserDetails,
            SalaryAndBonuses,
            Access,
            CustomPercentage,
            CredentialsManager,
            ProfileImage
        },
        data() {
            return {
                tabs: [
                    { name: 'Main details', component: 'MainUserDetails' },
                    { name: 'Profile Image', component: 'ProfileImage' },
                    { name: 'Salary & Bonuses', component: 'SalaryAndBonuses' },
                    { name: 'Credentials Manager', component: 'CredentialsManager' },
                    { name: 'Custom Percentage', component: 'CustomPercentage' },
                    { name: 'Access', component: 'Access' },
                ],
                selectedTab: 0,
                errors: {},
                item: this.modelValue || {},
            }
        },
        methods: {
            updateDetails() {
                this.$emit('update');
                this.getUserDetails();
            },
            getUserDetails() {
                this.ajax('getUserDetails', {
                    url: `/users/${this.item._id}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.item = body;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.addUserForm {
    display: grid;
    row-gap: 10px;
    padding: 50px 0 0 0;
}

.tabs {
    display: flex;
    flex-wrap: wrap;
    button {
        margin: 0 5px 5px 0;
        border: 1px solid $buttonColor;
    }
}

.accessForm {
    display: grid;
    row-gap: 30px;
}

.passwordChange {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ._cancel {
        color: $error;
        margin-left: 10px;
    }
}
</style>