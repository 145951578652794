<template>
    <div>

        <PageHeader title="Users">
            <div class="actionBtns">
                <button class="default round medium _icon" v-if="isUserPermitted('users', 'add')" @click="invite.modal = true">Invite user<i class="fa-solid fa-paper-plane"></i></button>
                <button class="default round medium _icon" v-if="isUserPermitted('users', 'add')" @click="addUser.modal = true">Add user<i class="fas fa-plus"></i></button>
            </div>
        </PageHeader>
        


        <ModalSimple title="Create user" confirm="Create user" v-model="addUser.modal" :loading="isLoading(['handleRegisterUser'])" @confirm="registerUser">
            <AddUser v-if="addUser.modal" v-model="addUser.item" :errors="addUser.errors"/>
        </ModalSimple>


        <Modal v-model="editUser" @close="deselectUser" title="Edit user" :loading="isLoading(['getUserDetails', 'handleUserEdit'])">
            <EditUser v-if="selectedUser" v-model="selectedUser" @update="getUsers"/>
        </Modal>


        <ModalSimple v-model="invite.modal" :loading="isLoading(['handleSendInvitation'])" @close="closeInvitationModal" @confirm="handleSendInvitation" confirm="Send invitation" title="Invite user">
            <InviteUser v-if="invite.modal" v-model="invite.item" :errors="invite.errors"/>
        </ModalSimple>
       
        <div class="wrapper usersListGrid">

            <div class="tabs">
                <button class="default small round" :class="{ 'reverse' : key != selectedType }" :key="key" @click="selectedType = key" v-for="(type, key) of types">{{ type.name }}</button>
            </div>

            <Table class="usersTable" :cols="['Select', '', 'Name', 'Positon', 'Username', 'Registered on', 'Action', '']">
                <div class="row" :key="key" v-for="(user, key) of users" @dblclick="editUserDetails(user)">
                    <div><input type="checkbox" name="selected" :value="user._id" v-model="selectedUsers"></div>
                    <div><div class="image" :style="`background-image: url('${user.profileImage}')`"></div></div>
                    <div>{{ user.name }}</div>
                    <div>{{ user.position }}</div>
                    <div><router-link :to="'/app/profile/' + user.username">{{ user.username }}</router-link></div>
                    <div>{{ registeredOn(user.registeredOn) }}</div>
                    <button class="default small reverse" @click="editUserDetails(user)">Edit</button>
                    <button class="default small reverse disableBtn" v-if="!user.disabled" @click="userRemove(user._id, true)"><i class="fa-solid fa-trash-can"></i></button>
                    <button class="default small reverse disableBtn" v-if="user.disabled" @click="userRemove(user._id, false)"><i class="fa-solid fa-rotate-left"></i></button>
                </div>
            </Table>
        </div>
        
    </div>
</template>

<script>
import AddUser from '../../components/users/AddUser.vue'
import EditUser from '../../components/users/EditUser.vue'
import InviteUser from '../../components/users/InviteUser.vue'
import moment from 'moment';
    export default {
        components: {
            AddUser,
            EditUser,
            InviteUser
        },
        data() {
            return {
                editUser: false,
                selectedUser: null,
                selectedUsers: [],
                users: [],
                invite: {
                    item: {},
                    errors: {},
                    modal: false
                },
                types: [
                    { name: 'Active', value: 'active' },
                    { name: 'Removed', value: 'disabled' }
                ],
                selectedType: 0,
                addUser: {
                    modal: false,
                    item: {},
                    errors: {}
                }
            }
        },
        computed: {
            apiURL() {
                return process.env.VUE_APP_API_URL;
            }
        },
        methods: {
            registerUser() {
                this.addUser.errors = {};

                this.ajax('handleRegisterUser', {
                    url: `/users`,
                    method: 'POST',
                    data: this.addUser.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.addUser.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `User has been successfully registered`
                    });

                    this.addUser.item = {};
                    this.addUser.modal = false;
                    this.getUsers();
                });
            },
            userRemove(id, value) {

                this.ajax('handleUserRemoveAction', {
                    url: `/users/disableAccount/${id}`,
                    method: 'POST',
                    data: {
                        value: value
                    }
                }, (err, body) => {
                    this.getUsers();
                });

            },
            handleSendInvitation() {
                this.invite.errors = {};
                this.ajax('handleSendInvitation', {
                    url: '/users/invite',
                    method: 'POST',
                    data: this.invite.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.invite.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$notify({
                        type: 'info',
                        text: `Invitation has been sent to ${this.invite.item.email} and will expiry in 24 hours.`
                    });
                    this.invite.item = {};
                    this.invite.modal = false;
                });
            },
            closeInvitationModal() {
                this.invite.errors = {};
                this.invite.item = {};
            },
            getUserImage(user) {
                if(!user.image) return '';
                return this.apiURL + user.image.path;
            },
            getUsers() {
                this.$axios.get(`/users?type=${this.types[this.selectedType].value}`)
                .then(data => {
                    this.users = data.data;
                });
            },
            registeredOn(date) {
                return moment(date, 'YYYY-MM-DD').format("DD MMM, YYYY");
            },
            editUserDetails(user) {
                this.selectedUser = JSON.parse(JSON.stringify(user));
                this.editUser = true;
            },
            pushUser(user) {
                this.users.push(user);
            },
            deselectUser() {
                this.selectedUser = null;
            }
        },
        mounted() {
            this.getUsers();
        },
        watch: {
            selectedType() {
                this.getUsers();
            }
        }
    }
</script>


<style lang="scss">
.usersTable {
    .row {
        grid-template-columns: 40px 40px repeat(4, minmax(0, 1fr)) 100px 34px !important;
    }
}
</style>

<style lang="scss" scoped>

.usersTable {
    .row {
        display: grid;
        grid-template-columns: 40px 40px repeat(4, minmax(0, 1fr)) 100px 34px !important;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}

.editBtn {
    border: 0;
    background: transparent;
    width: 34px;
    height: 34px;
    padding: 0;
}

.disableBtn {
    font-size: 10px;
    color: $error !important;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eee;
    background-size: cover;
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
}

.usersListGrid {
    display: grid;
    row-gap: 20px;
    .tabs {
        display: flex;
        button {
            margin-right: 10px;
        }
    }
}
</style>