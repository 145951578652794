<template>
    <div class="formDetailsHolder">


        <div class="rowOfThree">
            <div class="input">
                <div class="_header">
                    <label>Dispatch Percentage</label>
                    <span class="_error">{{ errors.dispatchPercentage }}</span>
                </div>
                <NumberSelector v-model="item.dispatchPercentage" name="%"/>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Salary</label>
                    <span class="_error">{{ errors.salary }}</span>
                </div>
                <NumberSelector v-model="item.salary" name="US$"/>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Team Bonus</label>
                    <span class="_error">{{ errors.teamBonus }}</span>
                </div>
                <NumberSelector v-model="item.teamBonus" name="US$"/>
            </div>
        </div>


        <div class="row">
            <div class="title">Disable weekly bonuses</div>
            <div class="value">
                <CheckSwitch v-model="item.disableWeeklyBonus"/>
            </div>
        </div>

        <div class="row">
            <div class="title">Disable monthly bonuses</div>
            <div class="value">
                <CheckSwitch v-model="item.disableMonthlyBonus"/>
            </div>
        </div>
        


        <button class="default" @click="handleSaveChanges">Save changes</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NumberSelector from '../dispatch/NumberSelector.vue'

    export default {
        components: {
            NumberSelector
        },
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
               this.errors = {};

                this.ajax('handleUserEdit', {
                    url: `/users/SalaryAndBonuses`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `User has been successfully updated.`
                    });
                    
                    this.$emit('update');
                });
            }
        },
        computed: {
            ...mapGetters(['positions'])
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 20px;
}

.rowOfThree {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>