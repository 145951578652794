<template>
    <div class="formDetailsHolder">

        <div class="input">
            <div class="_header">
                <label>Position</label>
                <span class="_error">{{ errors.position }}</span>
            </div>
            <div class="inputHolder">
                <select v-model="item.position">
                    <option :value="undefined">-- Select position --</option>
                    <option :key="key" v-for="(pos, key) of positions">{{ pos }}</option>
                </select>
            </div>
        </div>

        <Input v-model="item.name" name="Dispatch name" :error="errors.name" placeholder="Dispatch name"/>
        <Input v-model="item.dispatchPhone" name="Dispatch phone" :error="errors.dispatchPhone" placeholder="Dispatch phone" validate="phone"/>
        <Input v-model="item.dispatchEmail" name="Dispatch email" :error="errors.dispatchEmail" placeholder="Dispatch email"/>
        <Input v-model="item.fullName" name="Full name" :error="errors.fullName" placeholder="Full name"/>
        <Input v-model="item.email" name="Email address" :error="errors.email" placeholder="Email address"/>
        <Input v-model="item.username" name="Username" :error="errors.username" placeholder="Username"/>
        <Input v-model="item.pin" name="PIN" :error="errors.pin" placeholder="PIN (6 digits)"/>
        <Input v-model="item.briaExtension" name="Call Extension" :error="errors.briaExtension" placeholder="Extension"/>


        <button class="default" @click="handleSaveChanges">Save changes</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { parsePhoneNumber, AsYouType, isValidPhoneNumber } from 'libphonenumber-js'

    export default {
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
               this.errors = {};

                this.ajax('handleUserEdit', {
                    url: `/users/mainDetails`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `User has been successfully updated.`
                    });
                    
                    this.$emit('update');
                });
            }
        },
        computed: {
            ...mapGetters(['positions'])
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 10px;
}
</style>