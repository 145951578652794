<template>
    <div>


        <div class="numberSelectorInput" :class="{ 'error' : !validateIntOnly }">
            <input type="text" class="value" v-model="num" >
            <div class="name">{{ name }}</div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['modelValue', 'name'],
        data() {
            return {
                num: this.modelValue || ''
            }
        },
        methods: {
            mountModelValue() {
                if(typeof this.modelValue === 'string' || typeof this.modelValue === 'number') {
                    this.num = this.modelValue;
                } else {
                    this.num = '0';
                }
            }
        },
        mounted() {
            this.mountModelValue();
            this.$emit('update:modelValue', this.num);
        },
        computed: {
            validateIntOnly() {
                var regex = /^(?:[1-9][0-9]*|0)((?:[.]\d{1,2})?)$/;
                return regex.test(this.num); 
            }
        },
        watch: {
            modelValue() {
                this.mountModelValue();
            },
            num() {
                this.$emit('update:modelValue', this.num);
            }
        }
    }
</script>

<style lang="scss" scoped>
.numberSelectorInput {
    width: 100%;
    height: 34px;
    border-radius: 5px;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    color: $text;
    font-size: 13px;
    overflow: hidden;
    position: relative;
    &.error {
        border: 1px solid $error;
    }
    .value {
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0 10px;
        outline: none;
    }
    .name {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $buttonColor2;
        border-left: 1px solid $borderColor;
        font-weight: bold;
        color: $text;
    }
}
</style>