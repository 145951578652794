<template>
    <div class="formDetailsHolder">
        <div class="profilePicture" :style="`background-image:url('${item.profileImage}')`"></div>
        <button class="default medium round" @click="changeProfilePicture">Change image</button>
        <input type="file" name="photo" ref="photo" accept="image/png, image/jpg, image/jpeg, image/webp" @change="uploadProfilePhoto" hidden>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FileUploader from '../../mixins/UploadFile';

    export default {
        props: ['details'],
        mixins: [FileUploader],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {},
                apiURL: process.env.VUE_APP_API_URL
            }
        },
        methods: {
            uploadProfilePhoto() {
                this.fileSelected('photo', 'photo', `/users/UploadPicture/${this.item._id}`, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('update');
                });
            },
            changeProfilePicture() {
                this.$refs['photo'].click();
            },
        },
        computed: {
            ...mapGetters(['positions'])
        },
        watch: {
            details() {
                this.item = this.details ? JSON.parse(JSON.stringify(this.details)) : {};
            }
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 30px;
    justify-content: center;
    padding: 100px 0 0 0;
}


.profilePicture {
    width: 240px;
    height: 240px;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 3px solid $buttonColor;
}

</style>