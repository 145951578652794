<template>
    <div class="formDetailsHolder">

        <div class="listHolder">
            <div class="listTitle">
                <div class="title">Credentials</div>
                <button class="default small reverse" @click="addCredentials()"><i class="fa-solid fa-plus"></i></button>
            </div>
            <div class="list" v-if="list.length > 0">
                <div class="item" :key="item._id" v-for="item of list">

                    <div class="details">
                        <div class="detailsInfo">
                            <div class="name">{{ item.name }}</div>
                            <div class="username">{{ item.username }}</div>
                            <div class="password">{{ item.password }}</div>
                        </div>
                        <div class="actionBtns">
                            <button @click="item.edit = !item.edit"><i class="fa-solid fa-pencil"></i></button>
                            <button class="remove" @click="removeCredential(item._id)"><i class="fa-solid fa-trash-can"></i></button>
                        </div>
                    </div>

                    <div class="edit" v-if="item.edit">

                        <div class="form">
                            <div class="input">
                                <div class="_header">
                                    <label>Name</label>
                                </div>
                                <input type="text" class="inputValue" placeholder="Name" v-model="item.name">
                            </div>

                            <div class="input">
                                <div class="_header">
                                    <label>Username</label>
                                </div>
                                <input type="text" class="inputValue" placeholder="Username" v-model="item.username">
                            </div>

                            <div class="input">
                                <div class="_header">
                                    <label>Password</label>
                                </div>
                                <input type="text" class="inputValue" placeholder="Password" v-model="item.password">
                            </div>
                        </div>

                        <div class="saveButtonHolder">
                            <button class="default medium" @click="addCredentials(item)">Save changes</button>
                        </div>

                    </div>
                    


                </div>
            </div>
            <div class="noItemsMessage" v-else>No Credentials found.</div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomCarrierPercent from './CustomCarrierPercent.vue'

    export default {
        components: {
            CustomCarrierPercent
        },
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                list: []
            }
        },
        mounted() {
            this.getListOfUsersCredentials();
        },
        methods: {
            getListOfUsersCredentials() {
                if(!this.item || !this.item._id) return;
                this.ajax('handleUserEdit', {
                    url: `/users/Credentials/${this.item._id}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) {
                        this.list = body;
                    }
                });
            },
            addCredentials(data = { name: 'Untitled', username: '', password: '' }) {

                this.ajax('handleUserEdit', {
                    url: `/users/Credentials/${this.item._id}`,
                    method: 'POST',
                    data: data
                }, (err, body) => {
                    this.getListOfUsersCredentials();
                });

            },
            removeCredential(id) {

                this.ajax('handleUserEdit', {
                    url: `/users/Credentials/${id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    this.getListOfUsersCredentials();
                });

            }
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 20px;
}

.rowOfThree {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listHolder {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
    .listTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid $borderColor;
        padding: 0 20px;
        .title {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.noItemsMessage {
    padding: 20px;
    text-align: center;
}

.item {
    border-bottom: 1px solid $borderColor;
    padding: 20px;
    position: relative;
    display: grid;
    row-gap: 10px;
    &:last-child {
        border: 0;
    }

    .edit {
        border-top: 1px solid $borderColor;
        padding-top: 20px;
        display: grid;
        row-gap: 20px;
        .saveButtonHolder {
            display: flex;
            justify-content: flex-end;
        }
        .form {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 10px;
        }
    }
}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detailsInfo {
        font-size: 12px;
    }
    .name {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}

.inputValue {
    height: 34px !important;
}
</style>