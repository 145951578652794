<template>
    <div class="addUserForm">  

        <div class="input">
            <div class="_header">
                <label>Position</label>
                <span class="_error">{{ errors.position }}</span>
            </div>
            <div class="inputHolder">
                <select v-model="item.position">
                    <option :value="undefined">-- Select position --</option>
                    <option :key="key" v-for="(pos, key) of positions">{{ pos }}</option>
                </select>
            </div>
        </div>

        <Input v-model="item.name" name="Dispatch name" :error="errors.name" placeholder="Dispatch name"/>
        <Input v-model="item.fullName" name="Full name" :error="errors.fullName" placeholder="Full name"/>
        <Input v-model="item.email" name="Email address" :error="errors.email" placeholder="Email address"/>
        <Input v-model="item.username" name="Username" :error="errors.username" placeholder="Username"/>
        <Input v-model="item.password" name="Password" type="password" :error="errors.password" placeholder="Password"/>
        <Input v-model="item.repeatPassword" name="Repeat password" type="password" :error="errors.repeatPassword" placeholder="Repeat password"/>
        <Input v-model="item.pin" name="PIN" :error="errors.pin" placeholder="PIN (6 digits)"/>

        <Expandable title="Manage access">
            <PermissionSelector v-model="item.permissions"/>
        </Expandable>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionSelector from './PermissionSelector.vue'
import * as EmailValidator from 'email-validator';

    export default {
        components: {
            PermissionSelector,
        },
        props: ['modelValue', 'errors'],
        data() {
            return {
                item: this.modelValue || {}
            }
        },
        computed: {
            ...mapGetters(['positions'])
        },
        methods: {
            
        },
        watch: {
            modelValue() {
                this.item = this.modelValue || {};
            },
            item: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.item);
                }
            },
            "item.email"() {
                if(this.item.username && this.item.username.length > 0) return;
                if(EmailValidator.validate(this.item.email)) {
                    let usernameFromEmail = this.item.email.split('@');
                    if(usernameFromEmail[0]) {
                        this.item.username = usernameFromEmail[0];
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.addUserForm {
    display: grid;
    row-gap: 10px;
}


</style>