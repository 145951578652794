<template>
    <div class="itemHolder">

        <div class="row triple" :key="key" v-for="(item, key) of selection">
            <div class="title textNoWrap">{{ carriersObj[item.carrier] ? carriersObj[item.carrier].name : 'Unknown' }}</div>
            <div class="value">{{ item.percent }}%</div>
            <div class="removeBtn"><button class="remove" @click="remove(key)"><i class="fas fa-trash"></i></button></div>
        </div>

        <form class="carrierForm" action="" @submit.prevent="addCarrier">
            <select v-model="selectedCarrier">
                <option value="">-- Select Carrier --</option>
                <option :key="key" v-for="(item, key) of listOfCarriers" :value="item._id">{{ item.name }}</option>
            </select>
            <input type="text" v-model="percent" placeholder="Percentage">
            <button class="default" type="submit" :disabled="!isValidForm"><i class="fas fa-plus"></i></button>
        </form>
        
    </div>
</template>

<script>
    export default {
        props: ['modelValue'],
        data() {
            return {
                carriers: [],
                selectedCarrier: '',
                percent: 1,
                selection: this.modelValue || []
            }
        },
        methods: {
            getCarriers() {
                this.$axios.get('/dispatchCarriers')
                .then(data => {
                    this.carriers = data.data;
                });
            },
            getCarrier(id) {
                for(const item of this.carriers) {
                    if(item._id == id) return item.name;
                }
                return "Unknown"
            },
            addCarrier() {
                this.selection.push({
                    carrier: this.selectedCarrier,
                    percent: this.percent
                });
                this.selectedCarrier = '';
                this.percent = 1;
            },
            remove(index) {
                this.selection.splice(index, 1);
            }
        },
        computed: {
            carriersObj() {
                let result = {};
                for(const carrier of this.carriers) {
                    result[carrier._id] = result[carrier._id] || carrier;
                }
                return result;
            },
            isValidForm() {
                let pattern = /^[-+]?[0-9]+\.[0-9]+$/;
                return this.carriersObj[this.selectedCarrier] && !isNaN(parseFloat(this.percent)) && isFinite(this.percent);
            },
            listOfCarriers() {
                return this.carriers.filter(item => {
                    for(const carrier of this.selection) {
                        if(carrier.carrier == item._id) {
                            return false;
                        }
                    }
                    return true;
                });
            }
        },
        mounted() {
            this.getCarriers();
        },
        watch: {
            selection: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.selection);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>


.itemHolder {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}

.carrierForm {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 100px 40px;
    column-gap: 20px;
    grid-template-rows: 40px;
    padding: 10px 20px;
    border-top: 1px solid $borderColor;
    &:first-child {
        border: 0;
    }
    select, input {
        width: 100%;
        text-align: center;
        background: transparent;
        border: 0;
        padding: 0 10px;
    }
    select {
        text-align: left;
    }
    button {
        padding: 0;
        height: auto;
    }
}



.row {
    display: grid;
    height: 50px;
    align-items: center;
    padding: 0 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;

    &.triple {
        grid-template-columns: repeat(2, minmax(0, 1fr)) 20px;
    }

    &:nth-child(2n) {
        background: $bg;
    }
    .title {
        font-weight: bold;
    }
    .value {
        text-align: right;
    }
    .removeBtn {
        width: 20px;
        height: 20px;
        .remove {
            width: inherit;
            height: inherit;
            background: transparent;
            border: 0;
            font-size: 10px;
            color: $error;
        }
    }
}
</style>