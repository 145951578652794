<template>
    <div class="formDetailsHolder">

        <CustomCarrierPercent v-model="item.customCarriers"/>

        <button class="default" @click="handleSaveChanges">Save changes</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomCarrierPercent from './CustomCarrierPercent.vue'

    export default {
        components: {
            CustomCarrierPercent
        },
        props: ['details'],
        data() {
            return {
                item: this.details ? JSON.parse(JSON.stringify(this.details)) : {},
                errors: {}
            }
        },
        methods: {
            handleSaveChanges() {
               this.errors = {};

                this.ajax('handleUserEdit', {
                    url: `/users/CustomCarriersPercent`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {

                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }

                    this.$notify({
                        type: 'info',
                        text: `User has been successfully updated.`
                    });
                    
                    this.$emit('update');
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.formDetailsHolder {
    display: grid;
    row-gap: 20px;
}

.rowOfThree {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>